import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import Courses from "../components/courses"

const CoursesPage = () => {
  const { courses, chapters } = useStaticQuery(
    graphql`
      query {
        courses: allMarkdownRemark(
          filter: { fields: { pageType: { eq: "course" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                description
                themeColor
                thumbImagePath
                counting
              }
              fields {
                slug
                course
              }
            }
          }
        }
        chapters: allMarkdownRemark(
          filter: { fields: { pageType: { eq: "chapter" } } }
        ) {
          edges {
            node {
              fields {
                course
              }
            }
          }
        }
      }
    `
  )

  const chaptersEdges = chapters.edges
  const coursesEdges = courses.edges

  return (
    <Layout>
      <div className="font-bold text-3xl tracking-wide my-16">
        <h1>Let's start learning</h1>
        {/* TODO: add search field */}
      </div>
      <Courses coursesEdges={coursesEdges} chaptersEdges={chaptersEdges} />
    </Layout>
  )
}

export default CoursesPage
